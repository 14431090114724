import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: false,

  SERVICE_BASE_URL: 'https://pl.api.test.patrongo.com',

  firebaseConfig: {
    apiKey: "AIzaSyAaAi2M7r6ugnzuDfHAQitRqOIJQkpP0T8",
    authDomain: "patron-app-test-pl.firebaseapp.com",
    projectId: "patron-app-test-pl",
    storageBucket: "patron-app-test-pl.appspot.com",
    messagingSenderId: "310323720563",
    appId: "1:310323720563:web:8f5c6ebb96b93b84929506",
    measurementId: "G-MG9RQELS3Z"
  }
};
